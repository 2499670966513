import React from "react"

import popUpStyles from "./popUp.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

export default function PopUp({ msg, handleClose }) {
  return (
    <div className={popUpStyles.container}>
      <div className={popUpStyles.box}>
        <button onClick={handleClose} className={popUpStyles.close}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        <span className={popUpStyles.message}>{msg}</span>
      </div>
    </div>
  )
}
