import React, { useState } from "react"

import HELM from "../components/helmet.js"
import Layout from "../components/layout/layout.js"
import contactStyles from "../utilities/pageStyles/contact.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapPin } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { useForm } from "react-hook-form"
import PopUp from "../components/popUp/popUp"

export default function FAQ() {
  const { register, errors, handleSubmit } = useForm()

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const [msg, setMsg] = useState(null)
  const [pop, setPop] = useState(false)

  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => {
        setMsg("Thanks for reaching out. We'll get back to you shortly.")
        setPop(true)
      })
      .catch(() => {
        setMsg(
          "An error occurrd. Please try again later or reach us at lillyfeatherph@gmail.com"
        )
        setPop(true)
      })
  }

  return (
    <Layout>
      <HELM
        title="FAQ"
        keywords={[`Contact Us`, `Lilly Feather`, `Email`, `Phone`, `Message`]}
      />
      {pop ? <PopUp msg={msg} handleClose={() => setPop(false)} /> : null}
      <div className={contactStyles.contactContainer}>
        <div className={contactStyles.companyDetails}>
          <h1 className={contactStyles.companyInfoHeader}>Our Company Info</h1>
          <h2 className={contactStyles.companyInfoHeading}>Follow Us</h2>
          <a
            href="https://facebook.com/lillyfeatherph"
            target="_blank"
            rel="noreferrer"
            className={contactStyles.companyInfoItemSocial}
          >
            <div className={contactStyles.companyInfoIcon}>
              <FontAwesomeIcon icon={faFacebook} />
            </div>
            <span className={contactStyles.companyInfoText}>
              @lillyfeatherph
            </span>
          </a>
          <a
            href="https://www.instagram.com/lillyfeatherph/"
            target="_blank"
            rel="noreferrer"
            className={contactStyles.companyInfoItem}
          >
            <div className={contactStyles.companyInfoIcon}>
              <FontAwesomeIcon icon={faInstagram} />
            </div>
            <span className={contactStyles.companyInfoText}>
              @lillyfeatherph
            </span>
          </a>
          <h2 className={contactStyles.companyInfoHeading}>Email Us</h2>
          <a
            href="mailto:lillyfeatherph@gmail.com"
            className={contactStyles.companyInfoItem}
          >
            <div className={contactStyles.companyInfoIcon}>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <span className={contactStyles.companyInfoText}>
              lillyfeatherph@gmail.com
            </span>
          </a>
          <h2 className={contactStyles.companyInfoHeading}>Call Us</h2>
          <div className={contactStyles.companyInfoItem}>
            <div className={contactStyles.companyInfoIcon}>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className={contactStyles.mobileAvailability}>
              <span className={contactStyles.companyInfoText}>
                +63 917 894 7007
              </span>
              <p className={contactStyles.availability}>
                We're available Mondays to Fridays, 9 AM - 5 PM PST
              </p>
              <p className={contactStyles.availability}>
                For quicker replies, visit our social media pages or chat with
                us.
              </p>
            </div>
          </div>

          <h2 className={contactStyles.companyInfoHeading}>
            Lilly Feather Headquarters
          </h2>
          <div className={contactStyles.companyInfoItem}>
            <div className={contactStyles.companyInfoIcon}>
              <FontAwesomeIcon icon={faMapPin} />
            </div>
            <p className={contactStyles.companyInfoText}>
              3686 Sun Valley Dr, Subdivision, Parañaque, 1700 Metro Manila,
              Philippines
            </p>
          </div>

          <iframe
            className={contactStyles.googleMap}
            title="Lilly Feather HQ Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1931.449661575374!2d121.03415470791141!3d14.490468534931583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397cf1d6ae024d1%3A0xb78742a9d77bed17!2s3686%20Sun%20Valley%20Dr%2C%20Para%C3%B1aque%2C%201709%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1605354912780!5m2!1sen!2sph"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
          ></iframe>
        </div>

        <div className={contactStyles.contactFormContainer}>
          <h2 className={contactStyles.contactFormHeading}>
            Have any questions for us?
          </h2>
          <p className={contactStyles.contactFormSubHeading}>
            If it's not so urgent, leave your message after the beep, and we'll
            get back to you as soon as possible!
          </p>
          <span className={contactStyles.contactFormBeep}>~Beep~</span>
          <form
            className={contactStyles.contactForm}
            data-netlify="true"
            name="contact"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label htmlFor="name" control className={contactStyles.label}>
              Name
            </label>
            <input
              className={contactStyles.nameInput}
              name="name"
              ref={register({ required: true })}
            />
            {errors.name && (
              <span className={contactStyles.error}>
                We got to know your name
              </span>
            )}
            <label htmlFor="email" className={contactStyles.label}>
              Email
            </label>
            <input
              type="email"
              className={contactStyles.emailInput}
              name="email"
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className={contactStyles.error}>
                Your email is required
              </span>
            )}
            <label htmlFor="message" className={contactStyles.label}>
              Message
            </label>
            <textarea
              className={contactStyles.messageInput}
              name="message"
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className={contactStyles.error}>
                What do you want to tell us?
              </span>
            )}
            <input type="hidden" name="form-name" value="contact" />
            <button className={contactStyles.submitButton} type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </Layout>
  )
}
